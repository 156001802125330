<template>
  <!-- Wrapper -->
  <div 
    class=" relative flex items-center w-full h-full"
    :class="[
      ifScroll 
        ? 'h-12' 
        : 'xl:h-20 2xl:h-24'
    ]"
  >
    <!-- Выезжающий контейнер для списка мессенджеров -->
    <div 
      class="rounded-b-2xl ease absolute w-full bg-white"
      :class="[
        ifScroll 
          ? 'messengers_container-scroll' 
          : 'messengers_container',
        isPhonesOpen 
          ? 'messengers_container__open duration-500 shadow-2xl' 
          : 'duration-500',
      ]"
    >
      <!-- isPhonesMoreOpen 
        ? 'messengers_container__open-more'
        : '' -->
      <!-- Контент -->
      <div 
        class="ease py-4"
        :class="[
          isPhonesOpen 
            ? 'visible opacity-1 delay-300 duration-700' 
            : 'invisible opacity-0'
        ]"
      >
        <!-- Список мессенджеров -->
        <ul class="xl:ml-6 2xl:ml-10 xl:text-xl ml-6 mr-4 text-lg">
          <li class="messengers-item">
            <a
              class="messengers-link"
              href="https://t.me/zina_design_studio"
              :title="
                $i18n.locale === 'uk'
                  ? 'Написати в Telegram'
                  : 'Написать в Telegram'
              "
            >
              <span class="messengers-icon__container">
                <icon-base
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="w-8 h-8"
                  style="color: #039be5"
                  :title="
                    $i18n.locale === 'uk'
                      ? 'Написати в Telegram'
                      : 'Написать в Telegram'
                  "
                ><icon-telegram /></icon-base>
              </span>
              <span class="messengers-title">
                Telegram
              </span>
            </a>
          </li>
          <li class="messengers-item">
            <a
              class="messengers-link"
              href="viber://chat?number=+380503481711"
              :title="
                $i18n.locale === 'uk'
                  ? 'Написати в Viber'
                  : 'Написать в Viber'
              "
            >
              <span class="messengers-icon__container">
                <icon-base
                  width="32"
                  height="32"
                  viewBox="0 0 32 33.7"
                  class="w-8 h-auto"
                  style="color: #6e64c3"
                  :title="
                    $i18n.locale === 'uk'
                      ? 'Написати в Viber'
                      : 'Написать в Viber'
                  "
                ><icon-viber /></icon-base>
              </span>
              <span class="messengers-title">
                Viber
              </span>
            </a>
          </li>
          <li class="messengers-item">
            <a
              class="messengers-link"
              href="https://wa.me/380503481711"
              :title="
                $i18n.locale === 'uk'
                  ? 'Написати в WhatsApp'
                  : 'Написать в WhatsApp'
              "
            >
              <span class="messengers-icon__container">
                <icon-base
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="w-8 h-8"
                  style="color: #55cd6c"
                  :title="
                    $i18n.locale === 'uk'
                      ? 'Написати в WhatsApp'
                      : 'Написать в WhatsApp'
                  "
                ><icon-whatsapp /></icon-base>
              </span>
              <span class="messengers-title">
                WhatsApp
              </span>
            </a>
          </li>
          <!-- <li class="messengers-item">
              <a
                class="messengers-link"
                href="skype:romychvk?chat"
                :title="
                  $i18n.locale === 'uk'
                    ? 'Написати в Skype'
                    : 'Написать в Skype'
                "
              >
                <span class="messengers-icon__container">
                  <icon-base
                    width="32"
                    height="32"
                    viewBox="0 0 32.2 32.1"
                    class="w-8 h-8"
                    style="color: #2196f3"
                    :title="
                      $i18n.locale === 'uk'
                        ? 'Написати в Skype'
                        : 'Написать в Skype'
                    "
                  ><icon-skype /></icon-base>
                </span>
                <span class="messengers-title">
                  Skype
                </span>
              </a>
            </li> -->
            <!-- <li class="messengers-item">
              <a
                class="messengers-link facebook-messenger-bg bg-blue-white hover:text-white"
                href="https://m.me/Zina.Design"
                :title="
                  $i18n.locale === 'uk'
                    ? 'Написати в Facebook Messenger'
                    : 'Написать в Facebook Messenger'
                "
              >
                <img
                  src="/home/img/home/facebook-messenger.svg"
                  class="messengers-icon"
                />
                FB Messenger
              </a>
            </li> -->
            <li class="messengers-item">
              <a
                class="messengers-link"
                href="mailto:mail@zina.design"
                :title="
                  $i18n.locale === 'uk'
                    ? 'Написати електронною поштою'
                    : 'Написать по электронной почте'
                "
              >
                <span class="messengers-icon__container">
                  <icon-base
                    width="23"
                    height="18"
                    viewBox="0 0 23 18.1"
                    class="text-zina-teal w-6 h-auto ml-1"
                    :title="
                      $i18n.locale === 'uk'
                        ? 'Написати електронною поштою'
                        : 'Написать по электронной почте'
                    "
                  ><icon-mail /></icon-base>
                </span>
                <span class="messengers-title">
                  mail@zina.design
                </span>
              </a>
            </li>
            <li class="messengers-item">
              <a
                class="messengers-link"
                :href="
                  $i18n.locale === 'uk' 
                    ? '/uk/contacts/' 
                    : '/ru/contacts/'
                "
                :title="
                  $i18n.locale === 'uk'
                    ? 'Завітати до студії особисто'
                    : 'Зайти в студию лично'
                "
              >
                <span class="messengers-icon__container">
                  <icon-base
                    width="17"
                    height="24"
                    viewBox="0 0 17.4 24"
                    class="text-zina-teal w-auto h-6 ml-1"
                    :title="
                      $i18n.locale === 'uk'
                        ? 'Завітати до студії особисто'
                        : 'Зайти в студию лично'
                    "
                  ><icon-map /></icon-base>
                </span>
                <span class="messengers-title" v-if="$i18n.locale === 'uk'">Дніпро, Короленка 1</span>
                <span class="messengers-title" v-if="$i18n.locale === 'ru'">Днепр, Короленко 1</span>
              </a>
            </li>
          <!-- Дополнительный список мессенджеров -->
          <!-- <div :class="[
            isPhonesMoreOpen 
              ? 'opacity-1 delay-300 duration-700' 
              : 'opacity-0'
          ]">
            
          </div> -->
        </ul>
        <!-- <div
          @click.stop
          class="rounded-b-2xl bg-picton-blue-100 absolute bottom-0 items-center justify-center w-full h-12 text-gray-800 uppercase cursor-pointer"
          :class="[isPhonesMoreOpen ? 'hidden' : 'flex']"
          @click="phonesMoreOpen"
        >
          {{ $t("Much more") }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4 ml-0.5 mt-0.5 text-gray-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div> -->
      </div>
    </div>
    <!-- Верхний слой -->
    <div 
      class=" flex items-center justify-center w-full h-full"
    >
      <div
        v-click-outside="ClickOut"
        class="hidden lg:inline mb-0.5 ml-6 2xl:ml-1"
        :title="
          $i18n.locale === 'uk'
            ? 'Натисніть, якщо потрібен сайт'
            : 'Нажмите, если нужен сайт'
        "
        :class="{ 'animate__animated animate__tada': isPhoneHandHover }"
        @mouseover="isPhoneHandHover = true"
        @mouseout="isPhoneHandHover = false"
        @click="phonesOpen"
      >
      <svg 
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 37.7"
        class="land:block hidden text-gray-500 duration-500 ease-in-out transform"
        :class="[
          ifScroll 
            ? 'w-10' 
            : 'lg:w-10 xl:w-12'
        ]"
        fill="currentColor"
      >
        <path d="M22.6 4c0-2-.9-3.1-3.2-4H3.1C.8.8 0 2.5 0 4.8v27.7c0 2.4.6 4.2 2.9 5.2h16.6c.4-.3 1-.3 1.4-.6a4 4 0 0 0 1.8-3.4L22.6 4zM9.1 3.7h4.4c.3 0 .6.1.6.5s-.3.5-.6.5H9.2c-.3 0-.6 0-.7-.4 0-.4.3-.6.6-.6zm2.2 32.6c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4 2.4 1.1 2.4 2.4-1.1 2.4-2.4 2.4zm8.5-7.5c0 1-.3 1.3-1.3 1.3H4.1c-1 0-1.3-.3-1.3-1.3V8.9c0-1.2.2-1.4 1.3-1.4h14.2c1.1 0 1.3.2 1.3 1.3.2 6.7.2 13.4.2 20z" class="st0"/>
        <path fill="#fefefe" d="M2.9 18.8V8.9c0-1.2.2-1.4 1.3-1.4h14.2c1.1 0 1.3.3 1.3 1.3v20c0 1-.3 1.3-1.3 1.3H4c-1 0-1.3-.3-1.3-1.3.2-3.3.2-6.7.2-10z"/>
        <path fill="#fafafa" d="M11.3 4.7H9.2c-.3 0-.6 0-.7-.4 0-.4.3-.5.6-.5h4.4c.3 0 .6.1.6.5s-.3.5-.6.5l-2.2-.1z"/>
        <path d="M33.8.6c.1.5 0 1 .2 1.4.7 1.2 1.7 2.3 2.3 3.6A18.4 18.4 0 0 1 34 26.2c-.2.3-.1.9-.2 1.4.5-.1 1.1-.1 1.4-.4A20.3 20.3 0 0 0 35 .7L33.8.6z" class="st0"/>
        <path d="M30.2 3.7c0 .5-.1 1.1.1 1.4a14 14 0 0 1 0 17.9c-.2.3-.1.9-.1 1.3.5-.1 1.1-.1 1.3-.4a15.6 15.6 0 0 0-.2-20.1l-1.1-.1z" class="st0"/>
        <path d="M26.6 7.1c-.2.3-.2 1 .1 1.3a9 9 0 0 1 1.4 8.9c-.3.9-1 1.7-1.5 2.6-.2.4 0 .8 0 1.3.4-.1.9-.1 1.3-.4.3-.2.5-.6.7-.9 2.5-3.8 2.3-8.9-1-12.9-.3 0-.9-.1-1 .1z" class="st0"/>
      </svg>


        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          class="land:block hidden text-gray-500 duration-500 ease-in-out transform"
          :class="[
            ifScroll 
              ? 'w-8' 
              : 'lg:w-8 xl:w-10'
          ]"
          fill="currentColor"
          viewBox="0 0 27.2 28.5"
        >
          <path d="m16.8 9-.2.2c-.5.5-.5 1.4 0 1.9l.1.1c.5.5 1.4.5 1.9 0l1.8-1.8.2-.2c.5-.5.5-1.4 0-1.9l-.1-.1c-.5-.5-1.4-.5-1.9 0L16.8 9zm3.9.8-1.8 1.8c-.5.5-.5 1.4 0 1.9l.1.1c.5.5 1.4.5 1.9 0l.2-.2 1.6-1.6.2-.2c.5-.5.5-1.4 0-1.9l-.1-.1c-.5-.5-1.4-.5-1.9 0l-.2.2z"/>
          <path d="M26.7 16.2 25 14.5l.2-.2.2-.2c.5-.5.5-1.4 0-1.9l-.1-.2c-.5-.5-1.4-.5-1.9 0l-.2.2-1.6 1.6-.2.2c-.5.5-.5 1.4 0 1.9l.1.1c.5.5 1.4.5 1.9 0l.8-.8.7.7-8.9 8.9L7.2 16c.2-.7.3-1.4.4-1.8.3-2.1.7-4 .2-4.8-.7-1.1-2-1.1-2.6.6-.2.5-.5 1.7-.9 3L1.1 9.9l8.8-8.8 5 5-.5.5-.2.2c-.5.5-.5 1.4 0 1.9l.1.1c.5.5 1.4.5 1.9 0l.2-.2L18 7l.2-.2c.5-.5.5-1.4 0-1.9l-.1-.1c-.5-.5-1.4-.5-1.9 0l-.4.4L11 .5c-.7-.7-1.8-.7-2.4 0L.5 8.6c-.7.7-.7 1.8 0 2.5l3.4 3.4c-.2.6-.3 1.1-.5 1.7-.5 1.9-1 3.5-1 3.5-.5 1.3-.7 3.8 2.2 6.7.3.3.5.5.8.7.1.1.2.1.2.2.2.1.4.3.6.4.1 0 .1.1.2.1.2 0 .4.1.6.2.1 0 .2.1.3.1.1 0 .2.1.3.1.1 0 .3.1.4.1h.1c3 .6 5.2-1.6 5.2-1.6l1.5-1.5 1.4 1.4c.7.7 1.8.7 2.4 0l8.1-8.1c.7-.5.7-1.6 0-2.3zm-4.6 5.9c-.3.3-.9.3-1.2 0-.3-.3-.3-.9 0-1.2.3-.3.9-.3 1.2 0 .3.3.3.8 0 1.2z"/>
        </svg> -->
      </div>
      <div
        class="phone-or-button lg:flex relative items-center justify-center hidden w-56"
      >
        <transition name="phone-or-button" mode="out-in">
          <div v-if="!ifScroll">
            <a
              href="tel:+380503481711"
              class="hover:text-black text-gray-700"
              :class="[
                ifScroll 
                  ? 'lg:text-xl' 
                  : 'lg:text-xl 2xl:text-2xl'
              ]"
              >+<span
                class="text-bold hover:text-black text-gray-900"
                :title="
                  $i18n.locale === 'uk'
                    ? 'Зателефонувати до студії'
                    : 'Поговорить по телефону'
                "
                >38 050 348 17 11</span
              ></a
            >
          </div>
          <div v-else>
            <button-morph-modal
              @modal="setModal"
              :id="1"
              :buttonWidth="'11rem'"
              :buttonHeight="'2rem'"
              :buttonTextSize="'1rem'"
              :buttonTextColor="'#FEE2E2'"
              :buttonTextColorHover="'white'"
              :buttonColor="'#B91C1C'"
              :buttonColorActive="'#DC2626'"
              :buttonScale="150"
            >
              {{ $t("To order a site") }}
            </button-morph-modal>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
const IconBase = defineAsyncComponent(() => import("@/components/IconBase"));
const IconTelegram = defineAsyncComponent(() => import("@/components/icons/IconTelegram"));
const IconWhatsapp = defineAsyncComponent(() => import("@/components/icons/IconWhatsapp"));
const IconViber = defineAsyncComponent(() => import("@/components/icons/IconViber"));
// const IconSkype = defineAsyncComponent(() => import("@/components/icons/IconSkype"));
const IconMail = defineAsyncComponent(() => import("@/components/icons/IconMail"));
const IconMap = defineAsyncComponent(() => import("@/components/icons/IconMap"));
// import IconBase from '@/components/IconBase.vue'
// import IconTelegram from '@/components/icons/IconTelegram.vue'
// import IconWhatsapp from '@/components/icons/IconWhatsapp.vue'
// import IconViber from '@/components/icons/IconViber.vue'
// import IconSkype from '@/components/icons/IconSkype.vue'
// import IconPhone from '@/components/icons/IconPhone.vue'
// import IconMail from '@/components/icons/IconMail.vue'
// import IconMap from '@/components/icons/IconMap.vue'
const ButtonMorphModal = defineAsyncComponent(() => import("@/components/UI/ButtonMorphModal"));

export default {
  name: "button-messengers",

  components: {
    ButtonMorphModal,
    IconBase,
    IconTelegram,
    IconWhatsapp,
    IconViber,
    // IconSkype,
    // IconPhone,
    IconMail,
    IconMap,
    // IconTime
  },

  computed: {
    ...mapGetters({
      isBurgerActive: "select/selectIsBurgerActive",
      ifScroll: "select/ifScroll",
    }),
  },

  data() {
    return {
      isPhoneHandHover: false,
      isPhonesOpen: false,
      isPhonesMoreOpen: false,
    };
  },

  watch: {
    isPhonesOpen(val) {
      this.isPhonesMoreOpen = false;
      if (val == true) {
        document.addEventListener("keydown", this.Esc);
      } else {
        window.removeEventListener("keydown", this.Esc);
      }
    },
  },

  methods: {
    setModal(ev) {
      this.setModalVisible(ev);
    },
    ...mapMutations({
      setModalVisible: "modal/setModalVisible",
      setIsBurgerActive: "select/setIsBurgerActive",
    }),
    toggle() {
      this.setIsBurgerActive(!this.isBurgerActive);
    },
    phonesOpen() {
      this.isPhonesOpen = !this.isPhonesOpen;
    },
    phonesMoreOpen() {
      this.isPhonesMoreOpen = !this.isPhonesMoreOpen;
    },
    Esc(event) {
      if (event.code == "Escape") {
        this.isPhonesOpen = false;
      }
    },
    ClickOut(event) {
      if (event.isTrusted == true) {
        this.isPhonesOpen = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>

* {
  --navbar-height: 48px;
  @media (min-width: 1280px) { --navbar-height: 80px; }
  @media (min-width: 1536px) { --navbar-height: 96px; }
  --navbar-height-scroll: 48px;
  // --messengers-container-height: 280px;
  --messengers-container-height: 200px;
  @media (min-width: 1280px) { --messengers-container-height: 300px; }
  // --messengers-container-more-height: 420px;
  // @media (min-width: 1280px) { --messengers-container-more-height: 480px; }
  --total-height: calc(var(--messengers-container-height) + var(--navbar-height));
  --total-height-scroll: calc(var(--messengers-container-height) + var(--navbar-height-scroll));
}
.messengers_wrapper,
.messengers-top-layer {
  height: var(--navbar-height);
}
.messengers_wrapper-scroll,
.messengers-top-layer-scroll {
  height: var(--navbar-height-scroll);
}
.messengers_container {
  height: var(--total-height);
  padding-top: calc(var(--navbar-height) - 5px);
  top: calc(var(--total-height) * -1);
  transition: box-shadow 100ms 0ms, top 500ms 0ms;
}
.messengers_container-scroll {
  height: var(--total-height-scroll);
  padding-top: var(--navbar-height-scroll);
  top: calc(var(--total-height-scroll) * -1);
}
.messengers_container__open {
  top: 0px;
  transition: box-shadow 500ms 300ms, top 500ms 0ms;
}
.messengers_container-scroll.messengers_container__open {
  transition: box-shadow 400ms 200ms, top 400ms 0ms;
}
// .messengers_container__open-more {
//   height: calc(var(--messengers-container-more-height) + var(--navbar-height));
//   transition: height 400ms 0ms;
// }
// .messengers_container-scroll.messengers_container__open-more {
//   height: calc(var(--messengers-container-more-height) + var(--navbar-height-scroll));
// }

/* Непосредственно мессенджеры */ 

.messengers-item {
  @apply whitespace-nowrap mb-4;
}
.messengers-link {
  @apply flex items-center;
}
.messengers-icon__container {
  @apply inline-block w-9 h-9 mr-1;
}
.messengers-icon {
  @apply mx-auto;
}
.messengers-title {
  @apply mb-1.5;
}
.messengers-link:hover .messengers-title {
  @apply border-b border-gray-700;
}


/* Появление модалки */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Смена телефона на кнопку заказа при скролле, и обратно */
.lang-enter-active,
.phone-or-button-enter-active {
  transition: opacity 1.5s ease;
}
.lang-leave-active,
.phone-or-button-leave-active {
  transition: opacity 0.2s ease;
}
.lang-enter-from,
.phone-or-button-enter-from,
.lang-leave-to,
.phone-or-button-leave-to {
  opacity: 0;
}

.facebook-messenger-bg:hover {
  background: linear-gradient(
    to bottom left,
    rgb(255, 85, 126) 0%,
    rgb(166, 53, 248) 50%,
    rgb(26, 137, 255) 100%
  );
}
</style>
